import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    sidebarSize: (state) => state.sidebarSize,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    mode: (state) => state.mode,
    position: (state) => state.position,
    sidebarView: (state) => state.sidebarView,
    sidebarColor: (state) => state.sidebarColor
  })
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword'])

export const layoutMethods = mapActions('layout',
['changeLayoutType', 'changeLayoutWidth', 'changeSidebarSize', 'changeTopbar', 'changeMode', 'changePosition', 'changeSidebarView',
 'changeSidebarColor'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const todoComputed = {
  ...mapState('todo', {
    todos: (state) => state.todos
  })
}
export const todoMethods = mapActions('todo', ['fetchTodos']);

export const generateCode = (number = 6) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let code = '';

  for (let i = 0; i < number; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters[randomIndex];
  }

  return code;
}

export const detectExtensionFile = (file) => {
  const fileName = file.name;
  const fileExtension = fileName.split('.').pop().toLowerCase();

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const videoExtensions = ['mp4', 'avi', 'mov', 'quicktime'];

  if (imageExtensions.includes(fileExtension)) {
    return {
      type: 'image',
      extension: fileExtension
    };
  } else if (videoExtensions.includes(fileExtension)) {
    return {
      type: 'video',
      extension: fileExtension
    };
  } else {
    return false
  }
}

const convertMinute = (minute) => {
  let temp = parseInt(minute);
  if (temp === 0) return '00';
  return temp > 60 ? '60' : `${temp}`;
}
export const formatTime = (time) => {
  const pattern = /:/;

  switch (time.length) {
    case 0: {
      return `00:00`;
    }
    case 1: {
      return `0${time}:00`;
    }
    case 2: {
      return `${time}:00`;
    }
    case 3: {
      if (pattern.test(time)) {
        return `${time.slice(0,2)}:00`;
      } else {
        return `${time.slice(0,2)}:0${time.slice(-1)}`;
      }
    }
    case 4: {
      if (pattern.test(time)) {
        return `${time.slice(0,2)}:0${time.slice(-1)}`;
      } else {
        return `${time.slice(0,2)}:${convertMinute(time.slice(-2))}`;
      }
    }
    case 5: {
      return time;
    }
  }
}
